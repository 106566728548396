<div class="content">
    <h1 class="mb-3">Plebs & Pillagers</h1>
    <img src="../../assets/HeroCapsule_3840x1240.png" class="mb-3" aria-hidden="true"/>

    <div tabindex="0">
        Plebs & Pillagers is a village management game where you rebuild your kingdom.<br>
        Command your loyal villagers to help you on your quest, and to expand your realm while defending it from unknown threats.<br>
        <br>
        - Become the king of a forgotten realm and make it rise to its former glory.<br>
        - Build your village by collecting resources and assigning roles to your villagers.<br>
        - Upgrade your buildings to higher tiers to unlock new buildings and villager professions.<br>
        - Expand your kingdom with the help of quirky characters.<br>
        - Explore forgotten lairs and solve their mysteries to strengthen your reign, and to gain new powerful skills and perks.<br>
        - Make your villagers craft weapons and other equipment to defend your village from invading bandits. Will they be the only threat though?<br>
        <br>
        The king has returned!
    </div>
</div>
