import { createApp, h } from 'vue'
import App from './App.vue'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(fas, fab);

import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

import { createRouter, createWebHashHistory } from 'vue-router'
import welcome from './components/welcome/welcome.vue'
import about from './components/about/about.vue'
import contact from './components/contact/contact.vue'
import games from './components/games/games.vue'
import plebs_and_pillagers from './components/plebs_and_pillagers/plebs_and_pillagers.vue'

const routes = [
    {path: "/", component: welcome},
    {path: "/about", component: about},
    {path: "/contact", component: contact},
    {path: "/games", component: games},
    {path: "/plebs_and_pillagers", component: plebs_and_pillagers}
]

const router = createRouter({
    history: createWebHashHistory(),
    routes: routes,
    linkActiveClass: "active"
})

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'

const app  = createApp({
    render: ()=>h(App)
});
app.component('fa', FontAwesomeIcon, VueperSlides, VueperSlide);
app.use(router);
app.mount('#app');
