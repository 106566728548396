export default {
    name: 'About',
    props: {
      msg: String
    },
    data: () => ({
      slides: [
        {
          title: 'Slide #1',
          content: 'Slide 1 content.'
        },
        {
          title: 'Slide #2',
          content: 'Slide 2 content.'
        }
      ]
    })
}
