<div class="content">
    <h1 class="mb-3">Games</h1>
    <div class="row">
        <div class="col">
            <a href="#/plebs_and_pillagers" aria-label="Plebs & Pillagers">
                <img  src="../../assets/HeaderCapsule_460x215.png" />
            </a>
        </div>
        <div class="col">
            <a href="#/plebs_and_pillagers" aria-label="Plebs & Pillagers">
                <img  src="../../assets/HeaderCapsule_460x215.png" />
            </a>
        </div>
    </div>

</div>