<div class="header">
    <div class="header-images">
        <img src="../../assets/KloppferLP.png" class="kloppfer-face"/>
        <img src="../../assets/banner.png" class="header-background"/>
    </div>

    <div class="navigation row">
        <div class="col-2 nav-dropdown-menu">
            <fa :icon="['fas', 'bars']"  data-bs-toggle="dropdown" class="menu-icon"/>
            <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="#">Start</a></li>
                <li><a class="dropdown-item" href="#/games">Games:</a>
                    <li><a class="dropdown-item sub-item" href="#/plebs_and_pillagers">Plebs & Pillagers</a></li>
                </li>
                <li><a class="dropdown-item" href="#/about">About</a></li>
                <li><a class="dropdown-item" href="#/contact">Contact</a></li>
            </ul>
        </div>
        <div class="col-10 social-media-links">
            <a href="https://store.steampowered.com/app/2581360/Plebs_and_Pillagers/" target="_blank"
               aria-label="Link to steam page of plebs and pillagers">
                <fa :icon="['fab', 'steam']" />
                <label>Steam</label>
            </a>
            <a href="https://www.youtube.com/@kloppfer" target="_blank" aria-label="Link to youtube page of kloppfer">
                <fa :icon="['fab', 'youtube']" />
                <label>Youtube</label>
            </a>
            <a href="https://twitter.com/_Kloppfer_" target="_blank" aria-label="Link to x former twitter page of kloppfer">
                <fa :icon="['fab', 'x-twitter']" />
                <label>Twitter</label>
            </a>
        </div>
    </div>
</div>
