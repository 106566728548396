<template>
    <div class="page">
        <welcome/>
        <router-view></router-view>
    </div>
</template>

<script>
import welcome from './components/header/header.vue'

require('./style.css');

export default {
  name: 'App',
  components: {
    welcome
  }
}
</script>
